import IconButton from '../IconButton';
import DeleteIcon from '../Icons/DeleteIcon';
import styles from './styles.module.scss';
import classNames from 'classnames';

const DeleteButton = ({
  onClick,
  enabled = true
}: {
  onClick: (arg0: any) => void;
  enabled: boolean;
}) => {
  return (
    <div
      className={classNames(styles.deleteButton, enabled && styles.enabled)}
      onClick={onClick}
    >
      <IconButton IconComponent={DeleteIcon} />
    </div>
  );
};

export default DeleteButton;
