import React from 'react';
import { useAppContext } from '../../AppContext';
import { Form, init } from '@feathery/react';
import SaveModal from '../../SaveModal';
import styles from './styles.module.scss';

const SDK_KEY = '9cea2519-e0ff-4480-9b51-a8cdb0d55ff0';

const FormPreviewPage = () => {
  const { showSaveModal, getForm, isLoading } = useAppContext();
  const form = getForm();

  init(SDK_KEY);

  // Trigger reload of form when loader is showing
  return isLoading || !form ? null : (
    <>
      {showSaveModal && <SaveModal />}
      <div key={form.prompt} className={styles.formWrapper}>
        {/* @ts-ignore */}
        <Form formId={form.form_id ?? ''} _bypassCDN />
      </div>
    </>
  );
};

export default FormPreviewPage;
