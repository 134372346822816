const EditIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1474 1.66316L13.7263 5.2L14.9263 4C15.2491 3.67775 15.4035 3.28842 15.3895 2.832C15.3754 2.37558 15.207 1.98596 14.8842 1.66316L13.7053 0.484211C13.383 0.161404 12.9867 0 12.5162 0C12.0458 0 11.6491 0.161404 11.3263 0.484211L10.1474 1.66316Z'
        fill='#6C7589'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.57895 15.3684L12.5053 6.44211L8.92632 2.86316L0 11.7895V15.3684H3.57895ZM2.86316 13.6842H1.68421V12.5053L8.94737 5.24211L10.1263 6.42105L2.86316 13.6842Z'
        fill='#6C7589'
      />
      <path d='M7.15789 15.3684H16V13.6842H7.15789V15.3684Z' fill='#6C7589' />
    </svg>
  );
};

export default EditIcon;
