const MenuCloseIcon = () => {
  return (
    <svg
      width='43'
      height='43'
      viewBox='0 0 43 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='9.5459'
        y='30.7592'
        width='30'
        height='3'
        rx='1.5'
        transform='rotate(-45 9.5459 30.7592)'
        fill='#070A24'
      />
      <rect
        x='30.759'
        y='32.8805'
        width='30'
        height='3'
        rx='1.5'
        transform='rotate(-135 30.759 32.8805)'
        fill='#070A24'
      />
    </svg>
  );
};

export default MenuCloseIcon;
