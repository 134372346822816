import styles from './styles.module.scss';
import Modal from '../components/Modal';
function SaveModalContent() {
  return (
    <div className={styles.saveModal}>
      <div className={styles.title}>Your form is saved to your browser!</div>
      <SavedArt />
      <div className={styles.description}>
        To access it, please{' '}
        <a
          href='https://app.feathery.io/#Sign In'
          target='_blank'
          rel='noopener noreferrer'
        >
          log&nbsp;in
        </a>{' '}
        or{' '}
        <a
          href='https://app.feathery.io/#Sign Up'
          target='_blank'
          rel='noopener noreferrer'
        >
          sign&nbsp;up
        </a>
      </div>
    </div>
  );
}

const SaveModal = () => {
  return (
    <Modal name={'save-modal'} closeable>
      <SaveModalContent />
    </Modal>
  );
};

export default SaveModal;

const SavedArt = () => {
  return (
    <svg
      width='134'
      height='122'
      viewBox='0 0 134 122'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M128.868 62.8466C128.868 79.1021 122.229 93.7549 111.583 104.287C101.165 114.704 86.7416 121 70.8296 121C55.032 121 40.6082 114.589 30.0765 104.287C19.4303 93.7549 12.7908 79.1021 12.7908 62.8466C12.7908 30.6792 38.7766 4.69336 70.8296 4.69336C102.883 4.69336 128.868 30.7937 128.868 62.8466Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M123.831 24.7265C126.424 24.7265 128.525 22.6252 128.525 20.0331C128.525 17.4409 126.424 15.3396 123.831 15.3396C121.239 15.3396 119.138 17.4409 119.138 20.0331C119.138 22.6252 121.239 24.7265 123.831 24.7265Z'
        fill='#F1F3F9'
      />
      <path
        d='M130.7 6.4106C132.47 6.4106 133.905 4.97554 133.905 3.2053C133.905 1.43506 132.47 0 130.7 0C128.93 0 127.495 1.43506 127.495 3.2053C127.495 4.97554 128.93 6.4106 130.7 6.4106Z'
        fill='#F1F3F9'
      />
      <path
        d='M14.6223 24.612C16.3925 24.612 17.8276 23.177 17.8276 21.4067C17.8276 19.6365 16.3925 18.2014 14.6223 18.2014C12.8521 18.2014 11.417 19.6365 11.417 21.4067C11.417 23.177 12.8521 24.612 14.6223 24.612Z'
        fill='#F1F3F9'
      />
      <path
        d='M6.04718 86.4286C9.33476 86.4286 11.9999 83.7635 11.9999 80.4759C11.9999 77.1883 9.33476 74.5232 6.04718 74.5232C2.7596 74.5232 0.0944824 77.1883 0.0944824 80.4759C0.0944824 83.7635 2.7596 86.4286 6.04718 86.4286Z'
        fill='#F1F3F9'
      />
      <path
        d='M103.583 32.3965C108.001 32.3965 111.583 35.9782 111.583 40.3965V104.287C101.166 114.704 86.7418 121 70.8297 121C55.0322 121 40.6084 114.59 30.0767 104.287V40.3965C30.0767 35.9782 33.6584 32.3965 38.0767 32.3965H103.583Z'
        fill='white'
        stroke='#D6DCE8'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M66.8724 63.6928L85.3489 45.2163L90.7722 50.6396L66.9743 74.4375L66.948 74.4112L66.8461 74.5131L50.8999 58.5669L56.3232 53.1436L66.8724 63.6928Z'
        fill='url(#paint0_linear_2922_29239)'
      />
      <path
        d='M91.664 84.2534H49.9951V89.5193H91.664V84.2534Z'
        fill='#D6DCE8'
      />
      <path
        d='M91.664 95.8154H49.9951V101.081H91.664V95.8154Z'
        fill='#D6DCE8'
      />
      <path
        d='M81.4757 107.492H60.1833V112.758H81.4757V107.492Z'
        fill='#D6DCE8'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2922_29239'
          x1='88.1153'
          y1='48.6518'
          x2='57.7794'
          y2='59.5269'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#27AE60' />
          <stop offset='1' stopColor='#8FE04F' />
        </linearGradient>
      </defs>
    </svg>
  );
};
