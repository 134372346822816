import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Caret from '../Icons/Caret';
const Drawer = ({
  title,
  children,
  onClose,
  onOpen,
  openByDefault = false
}: {
  title: string;
  children: React.ReactNode;
  onClose: () => void;
  onOpen: () => void;
  openByDefault?: boolean;
}) => {
  const [open, setOpen] = React.useState(openByDefault);

  const toggleDrawer = () => {
    setOpen(!open);
    if (!open) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <>
      <div className={styles.drawer}>
        <div className={styles.drawerHeader}>
          <div className={styles.drawerClose} onClick={toggleDrawer}>
            <div
              className={classNames(
                styles.drawerCloseIcon,
                open ? styles.open : styles.closed
              )}
            >
              <Caret />
            </div>
          </div>
          <div className={styles.drawerTitle}>{title}</div>
        </div>
        <div
          className={classNames(
            styles.drawerContent,
            open ? styles.open : styles.closed
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Drawer;
