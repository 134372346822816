const DeleteIcon = () => {
  return (
    <svg
      width='13'
      height='16'
      viewBox='0 0 13 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.888889 14.2222C0.888889 15.2 1.68889 16 2.66667 16H9.77778C10.7556 16 11.5556 15.2 11.5556 14.2222V3.55556H0.888889V14.2222ZM2.66667 5.33333H9.77778V14.2222H2.66667V5.33333ZM9.33333 0.888889L8.44444 0H4L3.11111 0.888889H0V2.66667H12.4444V0.888889H9.33333Z'
        fill='#6C7589'
      />
    </svg>
  );
};

export default DeleteIcon;
