const PlusIcon = ({ width = 32, height = 32, color = '#414859', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M17.5 4C17.5 3.17157 16.8284 2.5 16 2.5C15.1716 2.5 14.5 3.17157 14.5 4H17.5ZM14.5 28C14.5 28.8284 15.1716 29.5 16 29.5C16.8284 29.5 17.5 28.8284 17.5 28H14.5ZM14.5 4V28H17.5V4H14.5Z' />
      <path d='M4 14.5C3.17157 14.5 2.5 15.1716 2.5 16C2.5 16.8284 3.17157 17.5 4 17.5L4 14.5ZM28 17.5C28.8284 17.5 29.5 16.8284 29.5 16C29.5 15.1716 28.8284 14.5 28 14.5L28 17.5ZM4 17.5L28 17.5L28 14.5L4 14.5L4 17.5Z' />
    </svg>
  );
};

export default PlusIcon;
