import {QuickEditGETResponse} from '../../api';
import QuickEditor, {QuickForm} from '../QuickEditor';
import {useAppContext} from '../../AppContext';
import styles from './styles.module.scss';

const QuickEditPage = () => {
  const { onboardingId, setFormResponse, getForm } = useAppContext();

  const form = getForm();

  const onChange = (newFormData: QuickForm) => {
    const nextApiResponse: QuickEditGETResponse = {
      ...form,
      linear_form: newFormData,
      onboarding_id: onboardingId
    };
    setFormResponse(nextApiResponse);
  };

  if (!form) return null;

  return (
    <div className={styles.pageWrapper}>
      <QuickEditor formData={form.linear_form} onChange={onChange} />
    </div>
  );
};

export default QuickEditPage;
