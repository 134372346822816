import styles from './styles.module.scss';
import IconButton from '../IconButton';
import PlusIcon from '../Icons/PlusIcon';
import VerticalLineIcon from '../Icons/VerticalLineIcon';

const AddBar = ({
  onClick,
  topLine,
  bottomLine
}: {
  onClick: () => void;
  topLine?: boolean;
  bottomLine?: boolean;
}) => {
  return (
    <div className={styles.addBar}>
      {topLine && <VerticalLineIcon />}
      <IconButton
        IconComponent={PlusIcon}
        onClick={onClick}
        width={12}
        height={12}
        iconColor={'#6C7589'}
        className={styles.addButton}
      />
      {bottomLine && <VerticalLineIcon />}
    </div>
  );
};

export default AddBar;
