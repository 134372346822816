import React from 'react';

import styles from './styles.module.scss';

import Prompt from '../Prompt';
import {useAppContext} from '../../AppContext';

export default function PDFPage() {
  const { isMobileWidth } = useAppContext();

  return (
    <>
      <div className={styles.title}>PDF Migration Tool</div>
      <div className={styles.description}>
        Feathery AI converts your PDF into a digital form
        in just a few seconds. Polish the final experience in our powerful editor.
      </div>
      <div className={styles.promptContainer}>
        <Prompt column={isMobileWidth} primary source='pdf' />
      </div>
    </>
  );
}
