const MenuIcon = () => {
  return (
    <svg
      width='31'
      height='22'
      viewBox='0 0 31 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.012207'
        y='0.283325'
        width='30'
        height='3'
        rx='1.5'
        fill='#070A24'
      />
      <rect
        x='0.012207'
        y='9.28333'
        width='24'
        height='3'
        rx='1.5'
        fill='#070A24'
      />
      <rect
        x='0.012207'
        y='18.2833'
        width='17'
        height='3'
        rx='1.5'
        fill='#070A24'
      />
    </svg>
  );
};

export default MenuIcon;
