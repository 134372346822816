const Stars = () => {
  return (
    <svg
      width='26'
      height='20'
      viewBox='0 0 26 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.60979 13.1854L10.3203 9.12286L14.6744 7.62615C15.0631 7.55488 15.2964 7.19852 15.2964 6.84216C15.2964 6.55708 15.0631 6.20072 14.6744 6.12944L10.3203 4.56146L8.60979 0.570236C8.53204 0.213876 8.14328 6.10476e-05 7.83228 6.10352e-05C7.44352 6.10196e-05 7.05477 0.213876 6.97702 0.570236L5.34424 4.56146L0.912421 6.12944C0.601416 6.20072 0.368164 6.55707 0.368164 6.84216C0.368164 7.19852 0.601416 7.55488 0.912421 7.62615L5.34424 9.12286L6.97702 13.1854C7.05477 13.5417 7.44352 13.6843 7.83228 13.6843C8.14328 13.6843 8.53204 13.5417 8.60979 13.1854ZM16.9228 19.693L17.9754 17.193L20.6548 16.272C20.8941 16.2281 21.0376 16.0088 21.0376 15.7895C21.0376 15.6141 20.8941 15.3948 20.6548 15.3509L17.9754 14.386L16.9228 11.9299C16.8749 11.7106 16.6357 11.579 16.4443 11.579C16.2051 11.579 15.9658 11.7106 15.918 11.9299L14.9132 14.386L12.1859 15.3509C11.9945 15.3948 11.851 15.6141 11.851 15.7895C11.851 16.0088 11.9945 16.2281 12.1859 16.272L14.9132 17.193L15.918 19.693C15.9658 19.9123 16.2051 20 16.4443 20C16.6357 20 16.8749 19.9123 16.9228 19.693ZM23.3342 9.4737L22.5447 11.3487C22.5089 11.5132 22.3294 11.579 22.1859 11.579C22.0065 11.579 21.827 11.5132 21.7912 11.3487L21.0376 9.4737L18.9921 8.78291C18.8486 8.75001 18.7409 8.58554 18.7409 8.42107C18.7409 8.28949 18.8486 8.12501 18.9921 8.09212L21.0376 7.36843L21.7912 5.52633C21.827 5.36186 22.0065 5.26317 22.1859 5.26317C22.3294 5.26317 22.5089 5.36186 22.5447 5.52633L23.3342 7.36843L25.3438 8.09212C25.5232 8.12501 25.6309 8.28949 25.6309 8.42107C25.6309 8.58554 25.5232 8.75001 25.3438 8.78291L23.3342 9.4737Z'
        fill='#E2626E'
      />
    </svg>
  );
};

export default Stars;
