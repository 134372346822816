import React from 'react';
import { Dropdown, option } from '../Dropdown';
import styles from './styles.module.scss';
import DragIcon from '../Icons/DragIcon';
import DeleteButton from '../DeleteButton';
import TextInput from '../TextInput';
import { QuickField } from '../QuickEditor';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import classNames from 'classnames';
import { useAppContext } from '../../AppContext';

const aiFieldTypes = [
  option('Text', 'text'),
  option('Text Area', 'textarea'),
  option('Radio', 'radio'),
  option('Checkbox', 'checkbox'),
  option('Email', 'email'),
  option('Number', 'number'),
  option('Dropdown', 'dropdown'),
  option('Phone', 'phone'),
  option('File', 'file')
];

const typeFormOnlyFieldTypes = [
  option('Website', 'url'),
  option('Date', 'date'),
  option('Rating', 'rating'),
  option('Slider', 'slider')
];

const typeFormFieldTypes = [...aiFieldTypes, ...typeFormOnlyFieldTypes];

const QuickQuestionEditor = ({
  question,
  onChange,
  index,
  pageIndex,
  onDelete,
  onDrop,
  showDeleteButton = true,
  ...props
}: {
  question: QuickField;
  onChange: (question: QuickField) => void;
  index: number;
  pageIndex: number;
  onDelete: () => void;
  onDrop: (uuidA: string, uuidB: string) => void;
  showDeleteButton: boolean;
}) => {
  const { source } = useAppContext();
  const { drag, dragPreview, drop, isOver } = useDragAndDrop(
    question.uuid || '',
    `PAGE-${pageIndex}-QUESTION`,
    onDrop
  );

  const dragAndDropRefs = (element: any) => {
    dragPreview(element);
    drop(element);
  };

  const dragHandleRef = (element: any) => {
    drag(element);
  };

  const onChangeLabel = (label: string) => {
    onChange({ ...question, question_text: label });
  };

  const onChangeType = (type: string) => {
    onChange({ ...question, type });
  };

  // Cannot edit this because image uploading is not yet implemented for the migration editor
  // TODO(drew): Implement image uploading for migration editor so we can get rid of this edge case
  const typeFormTypes =
    question.type === 'button_group'
      ? [option('Button Group', 'button_group')]
      : typeFormFieldTypes;
  const fieldTypes = source === 'typeform' ? typeFormTypes : aiFieldTypes;

  const disableDropdown =
    source === 'typeform' && question.type === 'button_group';

  return (
    <div
      ref={dragAndDropRefs}
      className={classNames(styles.quickField, isOver && styles.isOver)}
      style={{
        padding: 15
      }}
    >
      <div
        style={{
          padding: 15,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        ref={dragHandleRef}
      >
        <DragIcon />
      </div>
      <div className={styles.group}>
        <label>Label</label>
        <TextInput value={question.question_text} onChange={onChangeLabel} />
      </div>
      <div className={styles.group}>
        <label>Type</label>
        <Dropdown
          disabled={disableDropdown}
          hideLabel
          value={question.type}
          label={'Text'}
          options={fieldTypes}
          onChange={onChangeType}
        />
      </div>
      {showDeleteButton && (
        <DeleteButton enabled={showDeleteButton} onClick={onDelete} />
      )}
    </div>
  );
};
export default QuickQuestionEditor;
