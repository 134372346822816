import React from 'react';
import styles from './styles.module.scss';
import QuickPageEditor from '../QuickPageEditor';
import AddBar from '../AddBar';
import { v4 } from 'uuid';

// Example JSON
// {"form":{"language":"english","page_count":"any","pages":[{"page_number":1,"page_title":"Personal Information","questions":[{"question_text":"What is your full name?","type":"text"},{"question_text":"What is your date of birth?","type":"text"},{"question_text":"What is your email address?","type":"email"},{"question_text":"What is your phone number?","type":"phone"},{"question_text":"What is your gender?","type":"dropdown","options":["Male","Female","Other"]}]},{"page_number":2,"page_title":"Education","questions":[{"question_text":"What is the highest degree you have obtained?","type":"dropdown","options":["High School","Associate's Degree","Bachelor's Degree","Master's Degree","Doctorate"]},{"question_text":"What is the name of the institution where you earned your degree?","type":"text"},{"question_text":"What is your field of study or major?","type":"text"}]},{"page_number":3,"page_title":"Work Experience","questions":[{"question_text":"What is your current occupation?","type":"text"},{"question_text":"What is your work history?","type":"textarea"},{"question_text":"What is the name of your current employer?","type":"text"},{"question_text":"What is your current job title?","type":"text"}]}]}}

interface QuickEditProps {
  formData: QuickForm;
  onChange: (formData: QuickForm) => void;
}

export type QuickForm = {
  language?: string;
  page_count?: string;
  pages: QuickPage[];
};

export type QuickPage = {
  uuid?: string;
  page_number: number;
  page_title: string;
  questions: QuickField[];
};

export type QuickField = {
  uuid?: string;
  question_text: string;
  type: string;
  options?: (QuickOption | string)[];
  required?: boolean;
};

export type QuickOption = {
  uuid?: string;
  option_text: string;
};

const defaultQuickForm = {
  language: 'english',
  page_count: 'any',
  pages: [
    {
      page_number: 1,
      page_title: 'Personal Information',
      questions: [
        {
          question_text: 'What is your full name?',
          type: 'text'
        }
      ]
    }
  ]
};

const QuickEditor = ({ formData, onChange }: QuickEditProps) => {
  const [pages, setPages] = React.useState<QuickPage[]>(
    formData.pages || defaultQuickForm.pages
  );

  const saveForm = (pages: any) => {
    onChange({ ...formData, pages });
  };

  const addStep = (uuid: string | undefined) => {
    setPages((pages) => {
      const newSteps = [...pages];
      const index = newSteps.findIndex((step) => step.uuid === uuid);
      newSteps.splice(index + 1, 0, {
        page_number: newSteps.length + 1,
        page_title: `Step ${newSteps.length + 1}`,
        questions: [
          {
            question_text: 'Question',
            type: 'text',
            uuid: v4()
          }
        ],
        uuid: v4()
      });
      saveForm(newSteps);
      return newSteps;
    });
  };

  const deleteStep = (uuid?: string) => {
    setPages((pages) => {
      const newSteps = [...pages];
      const index = newSteps.findIndex((step) => step.uuid === uuid);
      newSteps.splice(index, 1);
      saveForm(newSteps);
      return newSteps;
    });
  };

  const changeStep = (uuid: string | undefined, page: QuickPage) => {
    setPages((pages) => {
      const newSteps = [...pages];
      const index = newSteps.findIndex((step) => step.uuid === uuid);
      newSteps[index] = page;
      saveForm(newSteps);
      return newSteps;
    });
  };

  return (
    <div className={styles.quickEdit}>
      {pages.map((page, i) => {
        const onDelete = () => deleteStep(page.uuid);

        const onAdd = () => addStep(page.uuid);

        return (
          <React.Fragment key={page.uuid}>
            <QuickPageEditor
              showDeleteButton={pages.length > 1}
              page={page}
              key={page.uuid + 'editor'}
              index={i}
              onDelete={onDelete}
              onChange={changeStep}
            />
            <AddBar
              key={page.uuid + 'add'}
              onClick={onAdd}
              topLine
              bottomLine={pages.length - 1 !== i}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default QuickEditor;
