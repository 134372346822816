import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Spinner from '../Spinner';

const Button = ({
  children,
  primary = false,
  className = '',
  loading = false,
  ...props
}: {
  children: React.ReactNode;
  onClick?: (arg0: any) => any;
  primary?: boolean;
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        primary && styles.primary,
        className,
        loading && styles.loading
      )}
      {...props}
    >
      {loading ? <Spinner style={{ marginRight: 5 }} /> : null}
      {children}
    </button>
  );
};

export default Button;
