/**
 * hook that changes the description of a page and then back to the original description
 */
import { useEffect } from 'react';

const useDescription = (description: string) => {
  useEffect(() => {
    // get the original description
    const originalDescription = document.querySelector(
      'meta[name="description"]'
    );

    if (!originalDescription) return () => {};

    // store the original description
    const originalContent = originalDescription.getAttribute('content');

    // set the new description
    originalDescription.setAttribute('content', description);

    return () => {
      if (originalContent) {
        // reset the description
        originalDescription.setAttribute('content', originalContent);
      }
    };
  }, [description]);
};

export default useDescription;
