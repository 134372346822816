import React from 'react';
import styles from './styles.module.scss';
import { QuickField, QuickOption } from '../QuickEditor';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import DeleteIcon from '../Icons/DeleteIcon';
import DragIcon from '../Icons/DragIcon';
import classNames from 'classnames';
import { v4 } from 'uuid';
const QuickOptionsEditor = ({
  question,
  onChange,
  questionIndex,
  onDrop
}: {
  question: QuickField;
  onChange: (question: QuickField) => void;
  questionIndex: number;
  onDrop: (questionUuid: string, uuidA: string, uuidB: string) => void;
}) => {
  const options = question.options;

  const addOption = () => {
    const newOptions = [...(options || [])];
    newOptions.push({ option_text: 'New Option', uuid: v4() });
    onChange({ ...question, options: newOptions });
  };

  return (
    <div className={styles.optionsEditor}>
      {(options || []).map((option, index, optionsx) => {
        if (typeof option === 'string') return null;
        const onDelete = () => {
          const newOptions = [...optionsx];
          newOptions.splice(index, 1);
          onChange({ ...question, options: newOptions });
        };

        const onChangeOption = (optionText: string) => {
          const newOptions = [...optionsx];
          newOptions[index] = { ...option, option_text: optionText };
          onChange({ ...question, options: newOptions });
        };

        const onDropOption = (uuidA: string, uuidB: string) => {
          if (!question.uuid) return;
          onDrop(question.uuid, uuidA, uuidB);
        };

        return (
          <Option
            key={option.uuid}
            option={option}
            index={index}
            onDelete={onDelete}
            onChange={onChangeOption}
            questionIndex={questionIndex}
            onDrop={onDropOption}
          />
        );
      })}
      <div
        className={classNames(styles.optionContainer, styles.addOption)}
        onClick={addOption}
      >
        Add&nbsp;Option
      </div>
    </div>
  );
};

const Option = ({
  option,
  onChange,
  index,
  onDelete,
  onDrop,
  questionIndex
}: {
  option: QuickOption;
  onChange: (value: string) => void;
  index: number;
  onDelete: () => void;
  onDrop: (uuidA: string, uuidB: string) => void;
  questionIndex: number;
}) => {
  const { drag, isOver, drop } = useDragAndDrop(
    option.uuid || '',
    `QUESTION-${questionIndex}-OPTION`,
    onDrop
  );

  const dragAndDropRefs = (element: any) => {
    drag(element);
    drop(element);
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div
      ref={dragAndDropRefs}
      className={classNames(styles.optionContainer, isOver && styles.isOver)}
      key={index}
    >
      <div className={styles.dragHandle}>
        <DragIcon width={6} height={12} />
      </div>
      <input type='text' value={option.option_text} onChange={onChangeText} />
      <div className={styles.deleteButton} onClick={onDelete}>
        <DeleteIcon />
      </div>
    </div>
  );
};

export default QuickOptionsEditor;
