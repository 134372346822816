import classNames from 'classnames';
import styles from './styles.module.scss';
import React from 'react';

const IconButton = ({
  IconComponent,
  onClick,
  className,
  width = 16,
  height = 16,
  iconColor,
  backgroundColor
}: {
  IconComponent: any;
  onClick?: (arg0: any) => void;
  className?: string;
  width?: number;
  height?: number;
  iconColor?: string;
  backgroundColor?: string;
}) => {
  return (
    <div
      className={classNames(className, styles.iconButton)}
      style={{
        backgroundColor,
        width: width + 8,
        height: height + 8,
        borderRadius: height + 8
      }}
      onClick={onClick}
    >
      <IconComponent width={width} height={height} color={iconColor} />
    </div>
  );
};

export default IconButton;
