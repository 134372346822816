import React from 'react';

import styles from './styles.module.scss';
import FullLogo from '../components/FullLogo';
import {openTab} from '../utils';
import classNames from 'classnames';
import {NavLink, useNavigate} from 'react-router-dom';
import {useAppContext} from '../AppContext';
import Button from '../components/Button';
import BackArrow from '../components/Icons/BackArrow';
import FeatheryIcon from '../components/Icons/FeatheryIcon';
import api, {QuickEditPATCHRequest} from '../api';
import {useModal} from '../components/Modal';
import MenuIcon from '../components/Icons/MenuIcon';

const OpenDrawerButton = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useAppContext();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div
      style={{ cursor: 'pointer', padding: '0 10px' }}
      onClick={toggleDrawer}
    >
      <MenuIcon />
    </div>
  );
};

const LogoAndBackButton = ({ showBackButton = true, backLocation = '', backDisabled = false }) => {
  const { isMobileWidth } = useAppContext();
  const history = useNavigate();

  return (
    <div>
      <OpenDrawerButton />
      <Button
        style={{
          backgroundColor: 'white'
        }}
        onClick={() => openTab('https://feathery.io')}
      >
        <FeatheryIcon />
      </Button>
      {showBackButton && (
        <Button
          disabled={backDisabled}
          onClick={() => {
            if (backLocation) {
              history(backLocation);
            } else {
              history(-1);
            }
          }}
        >
          <BackArrow />
          {!isMobileWidth && <span>Back</span>}
        </Button>
      )}
    </div>
  );
};

const PreviewHeader = () => {
  const history = useNavigate();
  const { setActiveModal } = useModal();
  const { onboardingId, source } = useAppContext();

  const saveAndShowModel = async () => {
    await api.saveForm(onboardingId, source);
    setActiveModal('save-modal');
  };

  const QuickEditAndSaveForm = () => {
    return (
      <div>
        <Button onClick={() => history('/edit')}>Edit&nbsp;Form</Button>
        <Button primary onClick={saveAndShowModel}>
          Save&nbsp;Form
        </Button>
      </div>
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerDivider}>
        <div className={styles.left}>
          <LogoAndBackButton showBackButton={false} />
        </div>
        <div className={styles.center} />
        <div className={styles.right}>{QuickEditAndSaveForm()}</div>
      </div>
    </div>
  );
};

const EditHeader = () => {
  const { getForm, onboardingId, source } = useAppContext();
  const form = getForm();

  const [isSaving, setIsSaving] = React.useState(false);

  const update = async () => {
    if (!form) return;

    setIsSaving(true);

    const updateRequest: QuickEditPATCHRequest = {
      linear_form: JSON.parse(JSON.stringify(form.linear_form))
    };

    await api.patchForm({
      onboardingId,
      request: updateRequest,
      source
    });

    // Loading can happen so fast that it looks broken, adding a delay to briefly see the spinner
    setTimeout(() => {
      setIsSaving(false);
    }, 300);
  };

  const SaveChangesButton = () => {
    return (
      <Button onClick={update} loading={isSaving}>
        Save
      </Button>
    );
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerDivider}>
        <div className={styles.left}>
          <LogoAndBackButton
            backLocation={source === 'ai' ? '/' : `/${source}`}
            backDisabled={isSaving}
          />
        </div>
        <div className={styles.center}>
          <div className={styles.promptHeader}>
            Prompt: <span>{form?.prompt}</span>
          </div>
        </div>
        <div className={styles.right}>{SaveChangesButton()}</div>
      </div>
    </div>
  );
};

const DefaultHeader = () => {
  const { setIsDrawerOpen, isMobileWidth } = useAppContext();

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div
      className={classNames(styles.header, styles.transparent)}
      style={{ position: 'fixed' }}
    >
      <div className={styles.headerDivider}>
        <div className={styles.left}>
          {isMobileWidth && <OpenDrawerButton />}
          <FullLogo
            className={classNames(styles.logo, styles.logoFull)}
            onClick={() => openTab('https://feathery.io')}
          />
        </div>
        <div className={styles.center}>
          {!isMobileWidth && (
            <>
              <NavLink
                to={'/'}
                onClick={closeDrawer}
                className={({ isActive }) => {
                  return isActive ? styles.active : '';
                }}
              >
                AI Form Generator
              </NavLink>
              <NavLink
                to={'/typeform'}
                onClick={closeDrawer}
                className={({ isActive }) => {
                  return isActive ? styles.active : '';
                }}
              >
                TypeForm Migration Tool
              </NavLink>

              <NavLink
                to={'/pdf'}
                onClick={closeDrawer}
                className={({ isActive }) => {
                  return isActive ? styles.active : '';
                }}
              >
                PDF Migration Tool
              </NavLink>
            </>
          )}
        </div>
        <div className={styles.right}>
          <button
            className={classNames(styles.secondaryButton, 'featheryButton')}
            onClick={() => openTab('https://app.feathery.io#Sign In')}
          >
            Log&nbsp;In
          </button>
          <button
            className='featheryButton'
            onClick={() => openTab('https://app.feathery.io#Sign Up')}
          >
            Sign&nbsp;Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default function Header({ header = '' }) {
  const renderHeader = () => {
    switch (header) {
      case 'preview':
        return <PreviewHeader />;
      case 'edit':
        return <EditHeader />;
      default:
        return <DefaultHeader />;
    }
  };

  return renderHeader();
}
