const DragIcon = ({
  width = 12,
  height = 22
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='4' height='4' rx='1' fill='#DBDFE8' />
      <rect x='8' width='4' height='4' rx='1' fill='#DBDFE8' />
      <rect y='9' width='4' height='4' rx='1' fill='#DBDFE8' />
      <rect x='8' y='9' width='4' height='4' rx='1' fill='#DBDFE8' />
      <rect y='18' width='4' height='4' rx='1' fill='#DBDFE8' />
      <rect x='8' y='18' width='4' height='4' rx='1' fill='#DBDFE8' />
    </svg>
  );
};

export default DragIcon;
