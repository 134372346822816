import { v4, v4 as uuid } from 'uuid';
import { QuickField, QuickOption, QuickPage } from '../components/QuickEditor';
import {
  GenerateFormPUTRequest,
  QuickEditGETResponse,
  QuickEditPATCHRequest,
  TypeFormPUTRequest
} from './types';
import { validateFromPlainObject } from '../utils/validation';

export * from './types';

const isLocal = process.env.REACT_APP_FEATHERY_ENV === 'local';
const localAPI = process.env.REACT_APP_LOCAL_API || 'http://localhost:8006';

const url = isLocal ? localAPI : 'https://api-static-2.feathery.io';

const generateForm = async ({
  onboardingId,
  request
}: {
  onboardingId: string;
  request: GenerateFormPUTRequest;
}) => {
  let curOnboardingId = onboardingId;
  if (!curOnboardingId) curOnboardingId = uuid().toString();

  const validationError = await validateFromPlainObject(
    request,
    GenerateFormPUTRequest
  );

  if (validationError) {
    return { error: validationError };
  }

  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify({ onboarding_id: curOnboardingId, ...request })
  };
  const res = await fetch(`${url}/onboarding/`, options);

  if (res.status !== 200) {
    let message;
    if (res.status === 400) {
      message = 'Prompt did not create a form. Please try again.';
    } else if ([429, 502].includes(res.status)) {
      message = 'Our servers are at capacity. Please try again later.';
    } else {
      message = 'An error occurred. Please try again.';
    }
    return { error: message };
  } else if (!onboardingId) {
    return { onboardingId: curOnboardingId };
  }

  const responseObject = await res.json();

  if (responseObject.error) {
    return responseObject;
  }

  return {
    ...responseObject,
    prompt: request.prompt,
    onboardingId: curOnboardingId
  };
};

const getForm = async ({
  onboardingId,
  source = 'ai'
}: {
  onboardingId: string;
  source: string;
}): Promise<QuickEditGETResponse | null> => {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'GET',
    cache: 'no-store' as RequestCache
  };

  const endpoint = new URL(`${url}/onboarding/quick/${onboardingId}/`);
  endpoint.searchParams.append('source', source);

  const res = await fetch(endpoint, options);

  if (res.status === 404) {
    return null;
  }

  if (res.status !== 200) {
    throw new Error('An error occurred. Please try again.');
  }

  const form = await res.json();

  if (Object.keys(form.linear_form).length > 0)
    addUUIDToPages(form.linear_form.pages);

  return form;
};

const patchForm = async ({
  onboardingId,
  request,
  source = 'ai'
}: {
  onboardingId: string;
  request: QuickEditPATCHRequest;
  source: string;
}) => {
  removeUUIDFromPages(request.linear_form.pages);

  // page_count should be a string when saving TODO(drew): move
  if (request.linear_form.page_count) {
    request.linear_form.page_count =
      request.linear_form.pages.length.toString();
  }

  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify(request)
  };

  const endpoint = new URL(`${url}/onboarding/quick/${onboardingId}/`);
  endpoint.searchParams.append('source', source);

  const res = await fetch(endpoint, options);

  if (res.status !== 200) {
    return { error: 'An error occurred. Please try again.' };
  }
  return res.json();
};

const saveForm = async (onboardingId: string, source = 'ai') => {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      onboarding_id: onboardingId,
      onboarding_source: source
    })
  };

  const endpoint = new URL(`${url}/onboarding/save/`);

  const res = await fetch(endpoint, options);
  return res.json();
};

const migrateForm = async (
  onboardingId: string,
  request: TypeFormPUTRequest
) => {
  const validationError = await validateFromPlainObject(
    request,
    TypeFormPUTRequest
  );

  if (validationError) {
    return { error: validationError };
  }

  let curOnboardingId = onboardingId;
  if (!curOnboardingId) curOnboardingId = uuid().toString();

  const options = {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify({ onboarding_id: curOnboardingId, ...request })
  };

  const res = await fetch(`${url}/onboarding/migrate/`, options);

  if (res.status !== 200) {
    return { error: 'An error occurred. Please try again.' };
  }

  const responseObject = await res.json();

  if (responseObject.error) {
    return responseObject;
  }

  return {
    onboardingId: curOnboardingId
  };
};

const migratePDFFormWithAI = async (
  formData: FormData,
  onboardingId: string
) => {
  let curOnboardingId = onboardingId;
  if (!curOnboardingId) curOnboardingId = uuid().toString();
  formData.append('onboarding_id', curOnboardingId);

  const options = { method: 'PUT', body: formData };

  const res = await fetch(`${url}/onboarding/pdf/ai/`, options);

  if (res.status !== 200) {
    return { error: 'An error occurred. Please try again.' };
  }

  const responseObject = await res.json();

  if (responseObject.error) {
    return responseObject;
  }

  return {
    onboardingId: curOnboardingId
  };
};

const api = {
  generateForm,
  getForm,
  patchForm,
  saveForm,
  migrateForm,
  migratePDFFormWithAI
};

export default api;

const addUUIDToQuestions = (page: QuickPage) => {
  for (let i = 0; i < page.questions.length; i++) {
    const question = page.questions[i];
    question.uuid = question.uuid || v4();
    if (question.options) {
      addUUIDToOptions(question);
    }
  }
};

const addUUIDToOptions = (question: QuickField) => {
  if (!question.options) return;
  for (let i = 0; i < question.options.length; i++) {
    const option = question.options[i];
    question.options[i] = {
      uuid: v4(),
      option_text: option as string
    };
  }
};

const addUUIDToPages = (pages: QuickPage[]) => {
  if (!pages) return;

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    page.uuid = page.uuid || v4();
    addUUIDToQuestions(page);
  }
};

const removeUUIDFromPages = (pages: QuickPage[]) => {
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    delete page.uuid;
    removeUUIDFromQuestions(page);
  }
};

const removeUUIDFromQuestions = (page: QuickPage) => {
  for (let i = 0; i < page.questions.length; i++) {
    const question = page.questions[i];
    delete question.uuid;
    if (question.options) {
      removeUUIDFromOptions(question);

      // Remove options if question isn't the right type
      if (!['dropdown', 'checkbox', 'radio'].includes(question.type)) {
        delete question.options;
      }
    }
  }
};

const removeUUIDFromOptions = (question: QuickField) => {
  if (!question.options) return;
  for (let i = 0; i < question.options.length; i++) {
    question.options[i] = (question.options[i] as QuickOption).option_text;
  }
};
