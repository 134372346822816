const DownArrow = () => {
  return (
    <svg
      width='10'
      height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.96141 1.34874L5.69305 5.19174C5.31436 5.66102 4.64227 5.66102 4.26357 5.19174L0.998627 1.34874C0.575582 0.820331 0.920159 0 1.56496 0H8.39849C9.03988 0 9.38446 0.820331 8.96141 1.34874Z'
        fill='#292C31'
      />
    </svg>
  );
};

export default DownArrow;
