import React from 'react';
import styles from './styles.module.scss';

const ToggleSwitch = ({
  id,
  checked,
  onChange
}: {
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className={styles.toggleSwitch}>
      <label htmlFor={id}>
        <input type='checkbox' id={id} checked={checked} onChange={onChange} />
        <span className={styles.toggleSwitchSlider}></span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
