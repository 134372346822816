import { useDrag, useDrop } from 'react-dnd';

const useDragAndDrop = (
  uuid: string,
  dragType: string,
  onDrop: (uuidA: string, uuidB: string) => void
) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: dragType,
    item: { uuid },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: dragType,
    drop: (item: { uuid: string }, monitor) => {
      onDrop(item.uuid, uuid);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  return { isDragging, drag, dragPreview, isOver, canDrop, drop };
};

export default useDragAndDrop;
