export const openTab = (url: any) => window.open(url, '_blank', 'noopener');

/**
 * @param key string corresponding to cookie name
 * @returns cookie value, or undefined if not found
 */
export function getCookie(key: string) {
  return document.cookie
    .split('; ')
    .filter((row: string) => row.startsWith(`${key}=`))
    .map((c: string) => c.split('=')[1])[0];
}

export function setCookie(key: string, val: string) {
  const domain =
    window.location.hostname === 'localhost' ? 'localhost' : 'feathery.io';
  document.cookie = `${key}=${val}; max-age=31536000; SameSite=strict; domain=${domain}`;
}
