const FeatheryIcon = () => {
  return (
    <svg
      width='17'
      height='28'
      viewBox='0 0 17 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6737 0.143925C15.863 0.555138 16.1336 1.24049 16.3229 2.17258C16.6746 3.87226 15.9983 6.22988 14.9162 7.4361C14.0537 8.39977 12.6851 9.16191 11.49 9.82736C11.2232 9.97593 10.9651 10.1197 10.7232 10.2598C9.88457 10.7532 9.07304 11.2192 8.26151 11.6853L8.26143 11.6853C7.72037 12.0006 7.18608 12.3159 6.65178 12.6311C6.11752 12.9464 5.58326 13.2616 5.04224 13.5769C4.9579 13.6251 4.87322 13.6733 4.78832 13.7216C3.8111 14.278 2.80456 14.851 1.95831 15.633C1.41727 16.1538 1.03854 16.7295 0.822128 17.4697C0.768024 17.6616 0.470452 17.7164 0.362244 17.5245C0.22534 17.3164 0.107681 15.8601 0.057967 15.2448C0.048741 15.1306 0.0418551 15.0453 0.0376206 15.0024C-0.0705874 13.2753 0.0105686 11.4934 0.876232 9.98563C1.41727 9.02613 2.22883 8.28594 3.12155 7.68283C3.79109 7.2305 4.50627 6.87068 5.22146 6.51087C5.45986 6.39093 5.69825 6.271 5.93495 6.14764C12.9955 2.52896 14.6457 1.18566 15.2138 0.143925C15.322 -0.0479749 15.5925 -0.0479749 15.6737 0.143925ZM14.1242 11.3252C14.3968 11.0799 14.5877 11.2162 14.7785 11.516C15.3509 12.4974 15.5418 14.0513 15.4872 15.1963C15.3602 17.9668 13.1003 19.031 10.8508 20.0903C10.6877 20.1672 10.5246 20.244 10.3623 20.3214L10.3413 20.3315C8.86656 21.0416 6.38065 22.2385 6.02795 24.0834C5.78261 25.2829 6.19151 26.2916 6.76398 27.1912C6.90028 27.4093 6.70946 27.6819 6.46411 27.6001C4.20152 26.9186 2.64768 25.2557 2.0207 23.2929C0.595622 18.9157 3.96923 17.1755 7.90583 15.1449C8.1862 15.0003 8.46942 14.8542 8.75397 14.7056C11.3982 13.3153 13.1429 12.2248 14.1242 11.3252Z'
        fill='#070A24'
      />
    </svg>
  );
};

export default FeatheryIcon;
