import {IsNotEmpty, IsString, Matches, MaxLength} from 'class-validator';
import {QuickForm} from '../../components/QuickEditor';

export const promptCharLimit = 256;

export type QuickEditGETResponse = {
  linear_form: QuickForm;
  onboarding_id: string;
  form_id?: string;
  prompt?: string;
};

export type QuickEditPATCHRequest = {
  linear_form: QuickForm;
  onboarding_id?: string;
  prompt?: string;
};

export class GenerateFormPUTRequest {
  @IsString()
  @MaxLength(promptCharLimit)
  @IsNotEmpty()
  prompt: string = '';

  @IsString()
  language?: string;

  @IsString()
  page_count?: string;
}

// Example URLs:
// https://form.typeform.com/to/ClI4prPS
// https://convictionvc.typeform.com/embed
export class TypeFormPUTRequest {
  @Matches(/https:\/\/.+\.typeform\.com\/(?:to\/)?[a-zA-Z0-9]+/, {
    message: 'Please enter a valid TypeForm URL'
  })
  url: string = '';
}
