/**
 * hook that changes the title of the page and then back to the original title
 */
import { useEffect } from 'react';

const useTitle = (title: string) => {
  useEffect(() => {
    // get the original title
    const originalTitle = document.title;

    // set the new title
    document.title = title;

    return () => {
      // reset the title
      document.title = originalTitle;
    };
  }, [title]);
};

export default useTitle;
