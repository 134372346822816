/**
 * This hook is used to change the og:image meta tag on the page
 */
import { useEffect } from 'react';

const useOgProperty = ({
  property,
  content
}: {
  property: string;
  content: string;
}) => {
  useEffect(() => {
    // get the meta image tag
    const ogProperty = document.querySelector(
      `meta[property="og:${property}"]`
    );

    if (!ogProperty) return () => {};

    // store the original image
    const originalContent = ogProperty.getAttribute('content');

    // set the new image
    ogProperty.setAttribute('content', content);

    return () => {
      if (originalContent) {
        // reset the image
        ogProperty.setAttribute('content', originalContent);
      }
    };
  }, [property, content]);
};

export default useOgProperty;
