import React from 'react';

import styles from './styles.module.scss';
import { useAppContext } from '../../AppContext';
import Prompt from '../Prompt';
import Stars from '../Icons/Stars';

const examplePrompts = [
  'An employee performance feedback form',
  'I want to collect emails for my new product launch',
  'A form to collect feedback on my new website'
];
export default function PromptPage() {
  const { setPrompt } = useAppContext();

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.title}>
        Create Web Forms 10x faster with AI.
        <div>{Stripe()}</div>
      </div>
      <div className={styles.description}>
        Feathery's AI form assistant lets you create high-quality forms in just
        a few seconds. Polish the final experience in our powerful editor.
      </div>
      <div className={styles.promptContainer}>
        <Prompt column />
      </div>
      <div className={styles.examplePromptContainer}>
        <div className={styles.exampleHeading}>
          <Stars />
          Try one of these:
        </div>
        {examplePrompts.map((examplePrompt) => (
          <div
            className={styles.examplePrompt}
            onClick={() => setPrompt(examplePrompt)}
            key={examplePrompt}
          >
            {examplePrompt}
          </div>
        ))}
      </div>
    </div>
  );
}

const Stripe = () => {
  return (
    <svg
      width='247'
      height='16'
      viewBox='0 0 247 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M245.656 1.57317C246.078 1.57317 246.5 1.79791 246.5 2.24739V3.37108C246.5 3.82056 245.656 4.0453 245.234 4.0453C224.98 3.82056 204.726 3.82056 184.473 4.0453C163.797 4.49477 143.121 5.16899 122.445 6.29268C101.769 7.41637 81.0935 8.54007 60.8396 10.338C41.4297 11.6864 22.4417 13.4843 3.45369 15.9564C2.18782 16.1812 0.5 15.507 0.5 14.608C0.5 12.5854 1.34391 10.5627 1.76587 8.76481C2.18782 8.09059 3.03173 7.41637 4.71955 7.19164C22.8636 5.39373 41.0077 4.27003 59.1518 3.14634C79.8276 2.02265 100.925 1.12369 121.601 0.674216C142.699 0.224739 163.375 0 184.473 0C204.726 0.224739 225.402 0.674216 245.656 1.57317Z'
        fill='url(#paint0_linear_2905_29975)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2905_29975'
          x1='0.5'
          y1='8.00001'
          x2='237.781'
          y2='7.99996'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF8397' />
          <stop offset='0.0001' stopColor='#FF4866' />
          <stop offset='1' stopColor='#FFA58A' />
        </linearGradient>
      </defs>
    </svg>
  );
};
