import React from 'react';

import styles from './styles.module.scss';

import Prompt from '../Prompt';
import {useAppContext} from '../../AppContext';

export default function MigrationPage() {
  const { isMobileWidth } = useAppContext();

  return (
    <>
      <div className={styles.title}>TypeForm Migration Tool</div>
      <div className={styles.description}>
        Feathery's migration tool lets you import forms from TypeForm into
        Feathery in just a few seconds. Polish the final experience in our
        powerful editor.
      </div>
      <div className={styles.promptContainer}>
        <Prompt column={isMobileWidth} primary source='typeform' />
      </div>
    </>
  );
}
