import React, {useEffect} from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import DownArrow from '../Icons/DownArrow';

type LabelValue = {
  label: string;
  value: string;
};

const option = (label: string, value?: string): LabelValue => {
  if (value === undefined) value = label.toLowerCase();
  return { label, value };
};

const DropdownContext = React.createContext<{
  active: string;
  onClick: (label: string, isOpen: boolean) => void;
}>({
  active: '',
  onClick: () => {}
});

const DropdownProvider = ({ children }: { children: React.ReactNode }) => {
  const [active, setActive] = React.useState<string>('');

  // Listen for mouse events outside of dropdown menu
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest(`.${styles.dropdown}`)) return;
      setActive('');
    };
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const onClick = (label: string, isOpen: boolean) => {
    if (isOpen) {
      setActive('');
    } else {
      setActive(label);
    }
  };

  return (
    <DropdownContext.Provider value={{ active, onClick }}>
      {children}
    </DropdownContext.Provider>
  );
};

const useDropdownHook = () => {
  const context = React.useContext(DropdownContext);
  if (context === undefined) {
    throw new Error(
      'useDropdownHook must be used within a DropdownHookProvider'
    );
  }
  return context;
};

const Dropdown = (props: {
  label: string;
  options: LabelValue[];
  value: any;
  hideLabel?: boolean;
  onChange: (value: string) => void;
  disabled?: boolean;
}) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [direction, setDirection] = React.useState<'up' | 'down'>('down');
  const [id] = React.useState<string>(Math.random().toString());
  const { active, onClick } = useDropdownHook();
  const { options, value, onChange, label, hideLabel, disabled } = props;

  const isOpen = active === label + id;

  const checkDropdownDirection = () => {
    if (dropdownRef.current === null) return;
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const dropdownHeight = dropdownRect.height;
    const dropdownBottom = dropdownRect.bottom;
    const windowHeight = window.innerHeight;
    if (dropdownBottom + dropdownHeight + 210 > windowHeight) {
      setDirection('up');
    } else {
      setDirection('down');
    }
  };

  // Check on resize
  useEffect(() => {
    window.addEventListener('resize', checkDropdownDirection);
    return () => window.removeEventListener('resize', checkDropdownDirection);
  }, []);

  // Check on scroll
  useEffect(() => {
    window.addEventListener('scroll', checkDropdownDirection);
    return () => window.removeEventListener('scroll', checkDropdownDirection);
  }, []);

  // Check on dropdown open
  useEffect(() => {
    checkDropdownDirection();
  }, [isOpen]);

  const onClickDropdown = () => {
    if (disabled === true) return;
    onClick(label + id, isOpen);
  };

  const onClickOption = (option: LabelValue) => {
    onClick(label + id, true);
    onChange(option.value);
  };

  const getOption = (value: string): string => options.find((option) => option.value === value)?.label || '';

  return (
    <div className={classNames(styles.dropdown, disabled && styles.disabled)}>
      <div
        className={styles.container}
        onClick={onClickDropdown}
        ref={dropdownRef}
      >
        {!hideLabel && <div className={styles.label}>{label}</div>}
        <div className={styles.currentValue}>{getOption(value)}</div>
        <DownArrow />
      </div>
      {isOpen && (
        <div
          className={classNames({
            [styles.options]: true,
            [styles[direction]]: true,
            [styles.open]: isOpen
          })}
        >
          <div className={styles.optionsContainer}>
            {options.map((option: any) => {
              return (
                <div
                  className={styles.option}
                  key={option.value}
                  onClick={() => onClickOption(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export { DropdownProvider, useDropdownHook, Dropdown, option };
