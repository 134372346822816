import React from 'react';
import styles from './styles.module.scss';
import { QuickField } from '../QuickEditor';
import ToggleSwitch from '../ToggleSwitch';

const OtherOptionsEditor = ({
  question,
  onChange
}: {
  question: QuickField;
  onChange: (question: any) => void;
}) => {
  const handleRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...question, required: e.target.checked });
  };

  // Required seems to be `true` by default if it doesn't exist as a property
  const defaultRequiredValue =
    question.required === undefined ? true : question.required;

  return (
    <div className={styles.otherOptionsEditor}>
      <div className={styles.otherOptionsEditorRow}>
        <label htmlFor={`required`}>Required</label>
        <ToggleSwitch
          id={`toggle-${question.uuid}`}
          checked={question.required || defaultRequiredValue}
          onChange={handleRequiredChange}
        />
      </div>
    </div>
  );
};

export default OtherOptionsEditor;
