export default function FullLogo(props: any) {
  return (
    <svg
      width='150'
      height='30'
      viewBox='0 0 150 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6737 0.143925C15.863 0.555138 16.1336 1.24049 16.3229 2.17258C16.6746 3.87226 15.9983 6.22988 14.9162 7.4361C14.0537 8.39977 12.6851 9.16191 11.49 9.82736C11.2232 9.97593 10.9651 10.1197 10.7232 10.2598C9.88457 10.7532 9.07304 11.2192 8.26151 11.6853L8.26143 11.6853C7.72037 12.0006 7.18608 12.3159 6.65178 12.6311C6.11752 12.9464 5.58326 13.2616 5.04224 13.5769C4.9579 13.6251 4.87322 13.6733 4.78832 13.7216C3.8111 14.278 2.80456 14.851 1.95831 15.633C1.41727 16.1538 1.03854 16.7295 0.822128 17.4697C0.768024 17.6616 0.470452 17.7164 0.362244 17.5245C0.22534 17.3164 0.107681 15.8601 0.057967 15.2448C0.048741 15.1306 0.0418551 15.0453 0.0376206 15.0024C-0.0705874 13.2753 0.0105686 11.4934 0.876232 9.98563C1.41727 9.02613 2.22883 8.28594 3.12155 7.68283C3.79109 7.2305 4.50627 6.87068 5.22146 6.51087C5.45986 6.39093 5.69825 6.271 5.93495 6.14764C12.9955 2.52896 14.6457 1.18566 15.2138 0.143925C15.322 -0.0479749 15.5925 -0.0479749 15.6737 0.143925ZM14.1242 11.3252C14.3968 11.0799 14.5877 11.2162 14.7785 11.516C15.3509 12.4974 15.5418 14.0513 15.4872 15.1963C15.3602 17.9668 13.1003 19.031 10.8508 20.0903C10.6877 20.1672 10.5246 20.244 10.3623 20.3214L10.3413 20.3315C8.86654 21.0416 6.38065 22.2385 6.02795 24.0834C5.78261 25.2829 6.19151 26.2916 6.76398 27.1912C6.90028 27.4093 6.70946 27.6819 6.46411 27.6001C4.20152 26.9186 2.64768 25.2557 2.0207 23.2929C0.595622 18.9157 3.96923 17.1755 7.90583 15.1449C8.1862 15.0003 8.46942 14.8542 8.75397 14.7056C11.3982 13.3153 13.1429 12.2248 14.1242 11.3252Z'
        fill='#070A24'
      />
      <path
        d='M33.6102 23.1867V15.4156H41.6192V11.7415H33.6102V7.35375H42.7294V3.65322H29.6982V23.1867H33.6102Z'
        fill='#070A24'
      />
      <path
        d='M58.1642 15.6535C58.1642 13.4068 57.477 11.6622 56.1289 10.367C54.7809 9.07186 53.0364 8.41105 50.9482 8.41105C48.7279 8.41105 46.9305 9.12472 45.5296 10.5521C44.1287 11.9794 43.4414 13.7768 43.4414 15.9707C43.4414 18.0589 44.1022 19.8563 45.4503 21.31C46.7983 22.7638 48.7279 23.5039 51.2654 23.5039C53.6443 23.5039 55.7325 22.8431 57.5563 21.5215L56.0761 18.9047C54.8338 19.7505 52.9835 20.3585 51.424 20.3585C49.1508 20.3585 47.7499 19.3276 47.2212 17.2923H58.0321C58.1114 16.7108 58.1642 16.1557 58.1642 15.6535ZM50.9482 11.5036C52.8513 11.5036 54.1994 12.6402 54.543 14.5169H47.2212C47.6706 12.6402 49.0715 11.5036 50.9482 11.5036Z'
        fill='#070A24'
      />
      <path
        d='M71.8825 10.4992C70.6138 9.09829 69.0807 8.41105 67.2833 8.41105C65.248 8.41105 63.5299 9.12472 62.1819 10.5521C60.8338 11.953 60.1466 13.7504 60.1466 15.9178C60.1466 18.0853 60.8074 19.9091 62.1554 21.3629C63.5035 22.8167 65.2216 23.5304 67.2833 23.5304C69.1072 23.5304 70.6402 22.8696 71.8825 21.5215V23.1867H75.4245V8.7811H71.8825V10.4992ZM71.9354 15.9707C71.9354 17.1337 71.5389 18.1117 70.7724 18.9311C70.0059 19.7505 69.0543 20.147 67.8913 20.147C66.7282 20.147 65.7767 19.7505 65.0101 18.9311C64.2436 18.1117 63.8735 17.1337 63.8735 15.9707C63.8735 14.8341 64.2436 13.8561 64.9837 13.0367C65.7502 12.2173 66.7018 11.8208 67.8913 11.8208C69.0543 11.8208 70.0059 12.2173 70.7724 13.0367C71.5389 13.8561 71.9354 14.8341 71.9354 15.9707Z'
        fill='#070A24'
      />
      <path
        d='M88.2654 19.4069C87.3931 19.9091 86.5737 20.147 85.86 20.147C84.512 20.147 83.8247 19.3012 83.8247 17.6095V11.9001H88.5826V8.7811H83.8247V3.67966H80.1242V8.7811H77.7453V11.9001H80.0978V17.8474C80.0978 21.5744 81.8159 23.5304 85.1199 23.5304C86.7852 23.5304 88.1861 23.1339 89.3755 22.3409L88.2654 19.4069Z'
        fill='#070A24'
      />
      <path
        d='M95.0574 23.1867V15.4685C95.0574 13.3275 96.379 11.8208 98.3614 11.8208C100.185 11.8208 101.375 13.0896 101.375 15.3892V23.1867H105.102V14.3848C105.102 12.4552 104.547 10.975 103.463 9.94413C102.379 8.91327 101.058 8.38462 99.498 8.38462C97.4627 8.38462 95.7182 9.36262 94.9516 10.6578V3.09814H91.3304V23.1867H95.0574Z'
        fill='#070A24'
      />
      <path
        d='M121.744 15.6535C121.744 13.4068 121.056 11.6622 119.708 10.367C118.36 9.07186 116.616 8.41105 114.527 8.41105C112.307 8.41105 110.51 9.12472 109.109 10.5521C107.708 11.9794 107.021 13.7768 107.021 15.9707C107.021 18.0589 107.682 19.8563 109.03 21.31C110.378 22.7638 112.307 23.5039 114.845 23.5039C117.224 23.5039 119.312 22.8431 121.136 21.5215L119.655 18.9047C118.413 19.7505 116.563 20.3585 115.003 20.3585C112.73 20.3585 111.329 19.3276 110.801 17.2923H121.611C121.691 16.7108 121.744 16.1557 121.744 15.6535ZM114.527 11.5036C116.431 11.5036 117.779 12.6402 118.122 14.5169H110.801C111.25 12.6402 112.651 11.5036 114.527 11.5036Z'
        fill='#070A24'
      />
      <path
        d='M127.505 23.1867V16.3408C127.505 13.3539 128.906 11.9794 131.021 11.9794C131.549 11.9794 132.131 12.0851 132.739 12.2702L133.056 8.70181C132.474 8.49035 131.893 8.38462 131.311 8.38462C129.54 8.38462 128.219 9.15116 127.347 10.7107V8.7811H123.778V23.1867H127.505Z'
        fill='#070A24'
      />
      <path
        d='M142.161 18.1646L138.434 8.7811H134.125L140.416 23.0281L140.125 23.689C139.412 25.1956 138.486 25.9621 137.323 25.9621C136.768 25.9621 136.134 25.8035 135.473 25.4864L134.839 28.6054C135.605 29.0812 136.53 29.319 137.614 29.319C140.469 29.319 142.319 27.5745 143.72 23.9004L149.509 8.7811H145.438L142.161 18.1646Z'
        fill='#070A24'
      />
    </svg>
  );
}
