/**
 * Side drawer component
 */

import React from 'react';
import styles from './styles.module.scss';
import { useAppContext } from '../../AppContext';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import MenuCloseIcon from '../Icons/MenuCloseIcon';

const SideDrawer = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useAppContext();

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div
      className={classNames(
        styles.drawer,
        (isDrawerOpen && styles.open) || styles.closed
      )}
    >
      <div className={styles.drawerContent}>
        <div className={styles.drawerHeader}>
          <div className={styles.drawerHeaderClose} onClick={closeDrawer}>
            <MenuCloseIcon />
          </div>
        </div>
        <div className={styles.drawerBody}>
          <NavLink
            to={'/'}
            onClick={closeDrawer}
            className={({ isActive }) => {
              return isActive ? styles.active : '';
            }}
          >
            AI Form Generator
          </NavLink>
          <NavLink
            to={'/typeform'}
            onClick={closeDrawer}
            className={({ isActive }) => {
              return isActive ? styles.active : '';
            }}
          >
            TypeForm Migration Tool
          </NavLink>
          <NavLink
            to={'/pdf'}
            onClick={closeDrawer}
            className={({ isActive }) => {
              return isActive ? styles.active : '';
            }}
          >
            PDF Migration Tool
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
