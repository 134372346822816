import { validate, ValidationError } from 'class-validator';
import { plainToInstance } from 'class-transformer';

/**
 * Convenience function to validate a plain object against a class type which is what class-validator expects.
 * @param plain The plain object to validate.
 * @param classType The class type with class-validator decorators to validate against.
 * @returns The first validation error message or null if there are no errors.
 * @see https://www.jsdocs.io/package/class-validator
 */
export const validateFromPlainObject = async (plain: any, classType: any) => {
  const instance = plainToInstance(classType, plain);
  const errors = await validate(instance);
  if (errors.length > 0) {
    return getFirstValidationErrorMessage(errors);
  }
  return null;
};

/**
 * Convenience function to get the first validation error message from an array of validation errors.
 * @param errors An array of validation errors.
 * @returns The first validation error message or null if there are no errors.
 */
export const getFirstValidationErrorMessage = (errors: ValidationError[]) => {
  if (errors.length > 0) {
    const firstError: ValidationError = errors[0];
    const constraints = firstError.constraints;
    if (constraints) {
      const message = Object.values(constraints)[0];
      if (message) {
        // Default class-validator error messages are lowercase. Capitalize the first letter and add period.
        return `${message.charAt(0).toUpperCase()}${message.slice(1)}.`;
      }
    }
  }
  return null;
};

const utils = {
  validateFromPlainObject,
  getFirstValidationErrorMessage
};

export default utils;
