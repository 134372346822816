import styles from './styles.module.scss';
import React from 'react';

const TextInput = ({
  value,
  onChange,
  placeholder,
  className,
  ...props
}: {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  props?: any;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <input
      type='text'
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className={`${styles.textInput} ${className}`}
      {...props}
    />
  );
};

export default TextInput;
