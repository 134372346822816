import React from 'react';
import styles from './styles.module.scss';
import CloseIcon from '../Icons/CloseIcon';

const ModalContext = React.createContext<{
  activeModal: string;
  setActiveModal: (modal: string) => void;
}>({
  activeModal: '',
  setActiveModal: () => {}
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeModal, setActiveModal] = React.useState<string>('');

  return (
    <ModalContext.Provider value={{ activeModal, setActiveModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

const Modal = ({
  name,
  children,
  closeable
}: {
  name: string;
  children: React.ReactNode;
  closeable?: boolean;
}) => {
  const { activeModal, setActiveModal } = useModal();

  if (activeModal !== name) return null;

  const closeModal = () => {
    setActiveModal('');
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalBody}>
        {closeable && (
          <div className={styles.modalHeader}>
            <div className={styles.closeButton} onClick={closeModal}>
              <CloseIcon />
            </div>
          </div>
        )}
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
