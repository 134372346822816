import React from 'react';
import EditIcon from '../Icons/EditIcon';
import styles from './styles.module.scss';

const EditableText = ({
  text,
  onChange
}: {
  text: string;
  onChange: (value: string) => void;
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [value, setValue] = React.useState(text);

  const handleBlur = () => {
    setEditMode(false);
    onChange(value);
  };

  const handleChange = (e: { target: { value: any } }) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e: { key: string }) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <div>
      {editMode ? (
        <input
          type='text'
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        <div className={styles.editableText} onClick={() => setEditMode(true)}>
          <span>{text}</span>
          <div className={styles.editIcon}>
            <EditIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditableText;
