import React from 'react';
import styles from './styles.module.scss';

const Spinner = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <div className={styles.spinner} {...props}>
      {/* These empty divs are required for the CSS spinner to work */}
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
