import classNames from 'classnames';
import styles from '../../styles.module.scss';
import Header from '../../Header';
import React from 'react';
import { useAppContext } from '../../AppContext';
import LogoLoader from '../LogoLoader';
import SideDrawer from '../SideDrawer';

const SharedLayout = ({
  children,
  header,
  subHeaderComponent,
  showGradientBackground,
  verticalCenter
}: {
  children: React.ReactNode;
  header?: string;
  subHeaderComponent?: React.ReactNode;
  showGradientBackground?: boolean;
  verticalCenter?: boolean;
}) => {
  const { isLoading } = useAppContext();

  return (
    <>
      <SideDrawer />
      <Header header={header} />
      {subHeaderComponent && (
        <div className={styles.subHeader}>{subHeaderComponent}</div>
      )}
      {isLoading && (
        <div
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            backgroundColor: 'white',
            width: '100%',
            height: '100%'
          }}
        >
          <LogoLoader />
        </div>
      )}
      <div
        className={classNames(
          styles.background,
          showGradientBackground && styles.gradient
        )}
      >
        <div className={styles.body} id={'shared-body'}>
          <div
            className={classNames(
              styles.center,
              verticalCenter && styles.verticalCenter
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedLayout;
