import logoLoader from './logoLoader.json';
import Lottie from 'lottie-react';
import styles from './styles.module.scss';

export default function LogoLoader() {
  return (
    <div className={styles.loaderContainer}>
      <Lottie
        className={styles.loader}
        animationData={logoLoader}
        loop
        autoplay
        renderer='svg'
      />
    </div>
  );
}
