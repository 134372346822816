import React from 'react';
import { ModalProvider } from './components/Modal';
import { DropdownProvider } from './components/Dropdown';
import Router from './Router';
import { AppContextProvider } from './AppContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SaveModal from './SaveModal';

function App() {
  return (
    <AppContextProvider>
      <DndProvider backend={HTML5Backend}>
        <DropdownProvider>
          <ModalProvider>
            <Router />
            <SaveModal />
          </ModalProvider>
        </DropdownProvider>
      </DndProvider>
    </AppContextProvider>
  );
}

export default App;
