const CloseIcon = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.244078 0.244078C0.569515 -0.0813592 1.09715 -0.0813592 1.42259 0.244078L9.75592 8.57741C10.0814 8.90285 10.0814 9.43049 9.75592 9.75592C9.43049 10.0814 8.90285 10.0814 8.57741 9.75592L0.244078 1.42259C-0.0813592 1.09715 -0.0813592 0.569515 0.244078 0.244078Z'
        fill='#6C7589'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75592 0.244078C9.43049 -0.0813592 8.90285 -0.0813592 8.57741 0.244078L0.244078 8.57741C-0.0813593 8.90285 -0.0813593 9.43049 0.244078 9.75592C0.569515 10.0814 1.09715 10.0814 1.42259 9.75592L9.75592 1.42259C10.0814 1.09715 10.0814 0.569515 9.75592 0.244078Z'
        fill='#6C7589'
      />
    </svg>
  );
};

export default CloseIcon;
